export const VERIFIED_CREATORS = [];

export const UPDATE_AUTHORITIES = [];
/*
export const UPDATE_AUTHORITIES = [
    'trshC9cTgL3BPXoAbp5w9UfnUMWEJx5G61vUijXPMLH', // DTP
    '4kovSxtW7oZ3J12VpUAV6JLBydebvLUV7ZYSAfndQr6E', // Tired Turtles
    'DC2mkgwhy56w3viNtHDjJQmc7SGu2QX785bS4aexojwX', // Degen Apes
    'DsuFKHaT2VWTQZhWAVJnoj7uJyB65PWDLEuP6dGn2sae', // Groms
    '8GV2kiCxbKjEGwLVfRa2Xdrb7sELL83cSx9jsPjMqrrd', // NWM
    'BHRFPSHHtLqjbcvVCmGrCjgbUagwnKDxp4CbUgoED3tT', // PIT
];*/